import React, { useState } from 'react';

function MainNav() {

  const [headerMenu, setHeaderMenu] = useState({
    isOpen: false
  });

  function toggleMenu() {
    setHeaderMenu({
      ...headerMenu,
      isOpen: !headerMenu.isOpen
    });

    window.document.body.classList.toggle('overflow-hidden');
  }

  function clickMenu() {
    setHeaderMenu({
      ...headerMenu,
      isOpen: false
    });

    window.document.body.classList.remove('overflow-hidden');
  }

  return (
      <nav className={headerMenu.isOpen ? 'main-nav main-nav--opened' : 'main-nav'}>
        <button 
          className='main-nav__toggle' 
          type='button'
          onClick={toggleMenu}
        >
        </button>
        <div className='main-nav__wrapper'>
          <ul className='main-nav__list'>
            <li className='main-nav__item'>
              <a href='#' onClick={clickMenu}>Главная</a>
            </li>
            {/*<li className='main-nav__item'>*/}
            {/*  <a href='#pageAboutMe'>Обо мне</a>*/}
            {/*</li>*/}
            {/*<li className='main-nav__item'>*/}
            {/*  <a href='#pageSkills'>Навыки</a>*/}
            {/*</li>*/}
            <li className='main-nav__item'>
              <a href='#pagePortfolio' onClick={clickMenu}>Портфолио</a>
            </li>
            <li className='main-nav__item'>
              <a href='#pageContacts' onClick={clickMenu}>Контакты</a>
            </li>
          </ul>

          {/*<div className='main-nav__lang-selection'>*/}
          {/*  <div className='lang-selection lang-selection--menu'>*/}
          {/*    <a className='lang-selection__lang' href='#'>RU</a> |*/}
          {/*    <a className='lang-selection__lang lang-selection__lang--active' href='#'>ENG</a>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </nav>
  );
}

export default MainNav;
