import React from 'react';
import PortfolioItem from './PortfolioItem';

function Portfolio() {
  return (
    <section className='portfolio'>
      <h2 className='portfolio__title' id='pagePortfolio'>Портфолио</h2>
      <ul className='portfolio__list'>
        <PortfolioItem
          nameProject='Калькулятор режимов резания'
          cover='/images/portfolio/milling_calculator.png'
          text='Калькулятор для расчёта технологических параметров резания для мобильных
            устройств на Android версии 4.1 и выше. Количество скачиваний: 40к+.'
          stack = {['java', 'android_studio']}
          moreLink = 'https://play.google.com/store/apps/details?id=com.millingcalculator.millingcalculator&hl=ru/'
        />

        <PortfolioItem
          nameProject='Арифметические упражнения'
          cover='/images/portfolio/arithmetic_exercises.png'
          text='Генератор арифметических упражнений и проверка ответа'
          stack = {['react', 'html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/arithmetic-exercises/'
          repositoryLink = 'https://github.com/f4rral/arithmetic-exercises'
        />

        <PortfolioItem
          nameProject='Игра "Крестики - Нолики"'
          cover='/images/portfolio/tic_tac_toe.png'
          text='За основу был взят пример из официальной документации по React и доработан.
            Добавлены: стили, алгоритм ответных ходов, ведение счёта.'
          stack = {['react', 'html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/tic-tac-toe/'
          repositoryLink = 'https://github.com/f4rral/tic-tac-toe/'
        />

        <PortfolioItem
          nameProject='Todo лист'
          cover='/images/portfolio/todo.png'
          text='Доработанный и стилизованный пример из официальной документации по Redux'
          stack = {['react', 'redux', 'html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/todo/'
          repositoryLink = 'https://github.com/f4rral/todo/'
        />

        <PortfolioItem
          nameProject='Перевод твердости'
          cover='/images/portfolio/hardness_conversion.png'
          text='Конвертирование значений твердости из шкалы Роквелла в шкалу по Виккерсу'
          stack = {['webpack', 'html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/hardness-conversion/'
          repositoryLink = 'https://github.com/f4rral/hardness-conversion/'
        />

        <PortfolioItem
          nameProject='Генератор QR-кода'
          cover='/images/portfolio/qr_skif_m.png'
          text='Генератор QR-кода различных размеров для тестирования мобильного приложения'
          stack = {['react', 'html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/qr-skif-m/'
          repositoryLink = ''
        />

        <PortfolioItem
          nameProject='Поиск аналогов'
          cover='/images/portfolio/rebounds.png'
          text='Поиск по обозначению в списке уже обработанных результатов'
          stack = {['react', 'html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/rebounds/'
          repositoryLink = ''
        />

        <PortfolioItem
          nameProject='Этикетка'
          cover='/images/portfolio/sticker-insert.png'
          text='Оформление этикетки согласно введенным данным с возможностью последующей печатью'
          stack = {['react', 'html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/sticker-insert/'
          repositoryLink = ''
        />

        <PortfolioItem
          nameProject='Расчёт твердости по Бринеллю'
          cover='/images/portfolio/hardness_measurement.png'
          text='Расчёт твердости по шкале Бринелля согласно замеренным значениям'
          stack = {['html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/hardness-measurement/'
          repositoryLink = 'https://github.com/f4rral/hardness-measurement/'
        />

        <PortfolioItem
          nameProject='Силы резания'
          cover='/images/portfolio/cuting_force.png'
          text='Построение графика сил резания согласно введенным технологическим параметрам обработки'
          stack = {['html', 'css', 'javascript']}
          moreLink = 'https://dimdev.ru/projects/cuting-force/'
          repositoryLink = ''
        />
      </ul>
    </section>
  );
}

export default Portfolio;
