import React from 'react';

function Preview() {
  return (
    <section className="preview">
      <div className="preview__header">
        <h2 className="preview__title">Дмитрий Разиньков</h2>
        <p className="preview__subtitle">Начинающий Frontend разработчик<br/> 32 год, Белгород</p>
        {/*<div className="preview__lang-selection lang-selection">*/}
        {/*  <a className="lang-selection__lang" href="#">RU</a> |*/}
        {/*  <a className="lang-selection__lang lang-selection__lang--active" href="#">ENG</a>*/}
        {/*</div>*/}
      </div>

      <ul className='preview__contacts'>
        <li className='preview__contact_item'>
          <a className='' href='https://t.me/f4rral' target='_blank'>
            <img className='preview__contact_img' src='/images/logo/social/telegram.svg' width='40' alt='Telegram' />
          </a>
        </li>
        <li className='preview__contact_item'>
          <a className='' href='https://vk.com/ff4utr' target='_blank'>
            <img className='preview__contact_img' src='/images/logo/social/vk.svg' width='40' alt='Вконтакте' />
          </a>
        </li>
        <li className='preview__contact_item'>
          <a className='' href='https://github.com/f4rral' target='_blank'>
            <img className='preview__contact_img' src='/images/logo/technology/github.svg' width='40' alt='github.com' />
          </a>
        </li>
        <li className='preview__contact_item'>
          <a className='' href='https://hh.ru/resume/b7737904ff08ac487a0039ed1f76493464464d' target='_blank'>
            <img className='preview__contact_img' src='/images/logo/social/hh.svg' width='40' alt='github.com' />
          </a>
        </li>
      </ul>

      <div className="preview__cover">
        <picture>
          <source media="(min-width: 426px)" srcSet='/images/preview/preview.jpg' />
          <source media="(max-width: 425px)" srcSet='/images/preview/preview--m.jpg' />
          <img src='/public/images/preview/preview.jpg' alt="Denis Novik" />
        </picture>
      </div>
    </section>
  );
}

export default Preview;
