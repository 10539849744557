import React from 'react';

import Preview from 'components/Preview/Preview';
import MainNav from './components/MainNav/MainNav';
import AboutMe from './components/AboutMe/AboutMe';
import Skills from './components/Skills/Skills';
import Portfolio from './components/Portfolio/Portfolio';
import Contacts from './components/Contacts/Contacts';

function App() {
  return (
    <div className='page__wrapper'>
      <header className='page__header'>
        <div className='page__container '>
          <div className='page__container-wrapper'>
            <MainNav />
          </div>
        </div>
      </header>

      <main className='page__main'>
        <h1 className='visually-hidden'>Portfolio Denis Novik</h1>

        <div className='page__container'>
          <div className='page__container-wrapper page__preview'>
            <Preview />
          </div>
        </div>

        {/*<div className='page__container page__container--bg-gray'>*/}
        {/*  <div className='page__container-wrapper page__about-me' id='pageAboutMe'>*/}
        {/*    <AboutMe />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className='page__container'>*/}
        {/*  <div className='page__container-wrapper page__skills' id='pageSkills'>*/}
        {/*    <Skills />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className='page__container page__container--bg-gray'>
          <div className='page__container-wrapper page__portfolio'>
            <Portfolio />
          </div>
        </div>
      </main>

      <footer className='page__footer'>
        <div className='page__container'>
          <div className='page__container-wrapper page__contacts'>
            <Contacts />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
