import React from 'react';

function PortfolioItem(props) {
  return (
    <li className='portfolio__item'>
      <h3 className='portfolio__name-project'>
        {props.nameProject}
      </h3>

      <div className='portfolio__info'>
        <div className='portfolio__cover'>
          <img
            className='portfolio__img'
            src={props.cover}
            alt={props.nameProject}
          />
        </div>
        <div className='portfolio__description'>
          <p className='portfolio__text'>
            {props.text}
          </p>

          <ul className='portfolio__stack_list'>
            {
              props.stack.map(item => (
                <li className='portfolio__stack_item' key={item}>
                  <img
                    className='portfolio__stack_logo'
                    src={`images/logo/technology/${item}.svg`}
                    alt={item}
                    title={item}
                  />
                </li>
              ))
            }
          </ul>

          <div className='portfolio__links'>
            <div className='portfolio__btn_demo'>
              <a
                className='button'
                target='_blank'
                href={props.moreLink}
              >
                Посмотреть
              </a>
            </div>

            {
              props.repositoryLink ? (
                <div className='portfolio__repository'>
                  <a
                    className='button button--secondary'
                    href={props.repositoryLink}
                    target='_blank'
                  >
                    Репозиторий
                  </a>
                </div>
              ) : ''
            }
          </div>
        </div>
      </div>
    </li>
  );
}

export default PortfolioItem;
