import React from 'react';

function Contacts() {
  return (
    <section className='contacts'>
      <h2 className='contacts__title' id='pageContacts'>Контакты</h2>
      {/*<p className='contacts__subtitle'>*/}
      {/*  Want to know more or just chat?<br/>*/}
      {/*  You are welcome!*/}
      {/*</p>*/}

      {/*<div className='contacts__send-message'>*/}
      {/*  <a className='button' href='#'>Send message</a>*/}
      {/*</div>*/}

      <div className='contacts__social'>
        <div className='social'>
          <ul className='social__list'>
            <li className='social__item'>
              <img className='social__link-img' src='/images/logo/social/email.svg' width='40' alt='Email' />
              <a className='social__link' href='mailto:razinkov_dim@mail.ru' target='_blank'>
                razinkov_dim@mail.ru
              </a>
            </li>
            <li className='social__item'>
              <img className='social__link-img' src='/images/logo/social/telegram.svg' width='40' alt='Telegram' />
              <a className='social__link' href='https://t.me/f4rral' target='_blank'>
                t.me/f4rral
              </a>
            </li>
            <li className='social__item'>
              <img className='social__link-img' src='/images/logo/social/vk.svg' width='40' alt='Вконтакте' />
              <a className='social__link' href='https://vk.com/ff4utr' target='_blank'>
               vk.com/ff4utr
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Contacts;
